import * as React from "react";
import { HTMLAttributes, ReactElement, useMemo, useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { toVWDesktop, toVWMobile } from "../../../helpers/styles";
import { BREAKPOINTS } from "../../../helpers/theme";
import ArrowGreenIcon from "../../../assets/images/arrow_green.svg";

export enum ArrowButtonType {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export type ArrowButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  type?: ArrowButtonType;
  className?: string;
} & HTMLAttributes<HTMLButtonElement>;

export const ArrowButton = ({
  onClick,
  disabled = false,
  type = ArrowButtonType.LEFT,
  className,
  ...props
}: ArrowButtonProps): ReactElement<HTMLButtonElement> => {
  const [active, setActive] = useState<boolean>(false);

  const borderAnimation = useMemo(() => {
    return {
      transform: `scale(${active ? 0.85 : 1})`,
    };
  }, [active]);
  const borderStyle = useSpring(borderAnimation);

  const arrowAnimation = useMemo(() => {
    return {
      transform: `scaleX(${active ? 1.25 : 1}) rotate(${
        type === ArrowButtonType.LEFT ? "180deg" : "0deg"
      })`,
    };
  }, [active, type]);
  const arrowStyle = useSpring(arrowAnimation);

  return (
    <Container
      onClick={onClick}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onTouchStart={() => setActive(true)}
      onTouchEnd={() => setActive(false)}
      className={className}
      disabled={disabled}
      backgroundColor={active ? "#75c154" : "#012102"}
      {...props}
    >
      <Background style={borderStyle} aria-hideen={true} />
      <StyledSVG style={arrowStyle} src={ArrowGreenIcon} title={"Arrow"} />
    </Container>
  );
};

const Container = styled.button<{ backgroundColor: string }>`
  width: ${toVWMobile(46)}vw;
  height: ${toVWMobile(46)}vw;
  border: none;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(46)}vw;
    height: ${toVWDesktop(46)}vw;
  }
`;

const Background = styled(animated.div)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #012102;
  position: absolute;
`;

const StyledSVG = styled(animated.img)`
  width: ${toVWMobile(20)}vw;
  height: auto;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(20)}vw;
    height: auto;
  }
`;

export default ArrowButton;
