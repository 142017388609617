import * as React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useCallback } from "react";
import { toREM, vwDesktop, vwMobile } from "../../../helpers/styles";
import { BREAKPOINTS } from "../../../helpers/theme";
import { ArrowButton, ArrowButtonType } from "../ArrowButton";

export type PaginatorProps = {
  currentPage: number;
  totalPages: number;
  onClick: (page: number) => void;
  onPrevDisabled: boolean;
  onPrev: () => void;
  onNextDisabled: boolean;
  onNext: () => void;
  className?: string;
};

export const Paginator: React.FC<PaginatorProps> = ({
  currentPage,
  totalPages,
  onClick,
  onPrevDisabled,
  onPrev,
  onNextDisabled,
  onNext,
  className,
}: PaginatorProps) => {
  const rangeSize = isMobile ? 4 : 5;
  const getRange = useCallback((): Array<number> => {
    if (totalPages < rangeSize) {
      return [...Array(totalPages).keys()].map((v) => v + 1);
    }
    const totalRanges = Math.floor(totalPages / rangeSize);

    for (let i = 0; i <= totalRanges; i++) {
      const min = rangeSize * i + 1;
      let max = rangeSize * (i + 1);

      if (max > totalPages) {
        max = totalPages;
      }
      if (min <= currentPage && currentPage <= max) {
        const range = [];
        for (let j = min; j <= max; j++) {
          range.push(j);
        }
        return range;
      }
    }
    return [...Array(rangeSize).keys()].map((v) => v + 1);
  }, [currentPage, totalPages]);

  return (
    <Container className={className}>
      {totalPages > rangeSize && (
        <ArrowButton
          onClick={onPrev}
          type={ArrowButtonType.LEFT}
          aria-label={"Prev"}
          disabled={onPrevDisabled}
        />
      )}
      <PageNumbersContainer>
        {getRange().map((n) => (
          <PageNumber
            key={n}
            onClick={() => onClick(n - 1)}
            active={n === currentPage}
          >
            {n}
          </PageNumber>
        ))}
      </PageNumbersContainer>
      {totalPages > rangeSize && (
        <ArrowButton
          onClick={onNext}
          type={ArrowButtonType.RIGHT}
          aria-label={"Next"}
          disabled={onNextDisabled}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  direction: ltr;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-top: ${vwMobile(40)};
  gap: ${vwMobile(15)};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${vwDesktop(80)};
    gap: ${vwDesktop(20)};
  }
`;

const PageNumbersContainer = styled.div`
  display: flex;
  gap: ${vwMobile(4)};
  align-items: center;
  justify-content: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${vwDesktop(4)};
  }
`;

const PageNumber = styled.button<{ active: boolean }>`
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#75C154" : "transparent")};
  color: ${(props) => (props.active ? "#033305" : "#75C154")};
  width: ${vwMobile(40)};
  height: ${vwMobile(40)};
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 700;
  letter-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${vwDesktop(48)};
    height: ${vwDesktop(48)};
  }
`;

export default Paginator;
