import React, { FC, memo } from "react";
import PlaceholderImage from "../../assets/images/press-release-placeholder.png";

import { ROUTES } from "../../helpers/routes";
import { Image, Tag } from "../../types";
import AnimatedCard from "../AnimatedCard";
import { useStaticLabels } from "../../hooks/useStaticLabels";

export type PressReleaseCardProps = {
  thumbnail: Image;
  type: string;
  title?: string;
  date?: string;
  description?: string;
  slug?: string;
  tags?: Tag[];
  readMore: string;
};

const PressReleaseCard: FC<PressReleaseCardProps> = ({
  title,
  type,
  date,
  description,
  thumbnail,
  slug,
  readMore,
}: PressReleaseCardProps) => {
  const labels = useStaticLabels();

  return (
    <AnimatedCard
      link={`${ROUTES.PRESS_RELEASE}/${slug}`}
      image={{
        ...thumbnail,
        src: thumbnail?.src || PlaceholderImage,
        alt: thumbnail?.alt || labels?.article,
      }}
      readMore={readMore}
      type={type}
      typeColor="#75c154"
      date={date}
      title={title}
      description={description}
    />
  );
};

export default memo(PressReleaseCard, (a, b) => {
  return (
    a?.title === b?.title &&
    a?.description === b?.description &&
    a?.date === b?.date
  );
});
