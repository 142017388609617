import React from "react";
import styled from "styled-components";
import { toREM } from "../../../helpers/styles";
import { BREAKPOINTS } from "../../../helpers/theme";

export type HeaderProps = {
  title: string;
  className?: string;
};

const Header = ({ title, className }: HeaderProps) => {
  return (
    <Container
      className={className}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
};

const Container = styled.h1`
  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(48)}rem;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: ${toREM(52)}rem;
  text-transform: uppercase;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(80)}rem;
    font-weight: 900;
    letter-spacing: 0.03em;
    line-height: ${toREM(88)}rem;
  }
`;

export default Header;
